<template>
  <div class="flex flex-col w-full mt-6 px-15 lg:px-4 sm-ws:px-10">
    <div
      class="flex font-extrabold leading-2 text-promy-purple-400 text-promy-2xl font-main sm:text-lg xl:mb-12 lg:mb-4"
    >
      <span class="text-promy-gray-650">Vues pour ce dossier</span>
    </div>
    <div class="flex justify-end lg:text-base xl:text-lg">
      <router-link
        :to="{ name: 'list-dossier-promoteur' }"
        class="flex items-center justify-center px-4 py-2 text-sm font-extrabold text-white uppercase border-2 rounded-full cursor-pointer bg-promy-green-300 border-promy-green-300 font-main"
      >
        <i class="pr-2 text-base fas fa-arrow-circle-left"></i>
        <span> Retour</span>
      </router-link>
    </div>
    <!-- table -->
    <div class="xl:h-50-screen xl:overflow-y-auto liste-dossier">
      <template v-if="isFetching && current_page == 1">
        <div class="flex justify-center w-full mt-24">
          <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </template>
      <template v-else>
        <template v-if="vueDossierPromoteurs.length > 0">
          <pro-card>
            <pro-table-row :isHeader="true">
              <pro-table-header-cell
                v-for="(sort, index) in sort_list"
                :key="index"
                :class="sort.classes"
                :sortingBy="sorting_by"
                :hasSort="sort.sortKey ? true : false"
                :title="sort.title"
                :sortKey="sort.sortKey"
              />
            </pro-table-row>
            <pro-table-row
              v-for="(item, index) in vueDossierPromoteurs"
              :key="index"
              class="relative"
            >
              <pro-table-cell label="Offre" class="w-1/3 lg:w-full">
                {{ getValue(item.promoteur, 'nom') }}
              </pro-table-cell>
              <pro-table-cell label="Date" class="w-1/3 lg:w-full">
                {{ getValue(item.promoteur, 'prenom') }}
              </pro-table-cell>
              <pro-table-cell label="Utilisateur" class="w-1/3 lg:w-full">
                {{ getValue(item.promoteur, 'raison_social') }}
              </pro-table-cell>
              <pro-table-cell label="Utilisateur" class="w-1/3 lg:w-full">
                {{ item.seen }}
              </pro-table-cell>
            </pro-table-row>
          </pro-card>
          <template v-if="vueDossierPromoteurs.length > 0">
            <isVisible :isFetching="isFetching" @isVisible="isVisible" />
          </template>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.getVueDossierPromoteurs()
  },
  data() {
    return {
      sort_list: [
        {
          title: 'NOM',
          sortKey: '',
          classes: 'w-1/3',
        },
        {
          title: 'PRENOM',
          sortKey: '',
          classes: 'w-1/3',
        },
        {
          title: 'RAISON SOCIAL',
          sortKey: '',
          classes: 'w-1/3',
        },
        {
          title: 'NOMBRE DE VUES',
          sortKey: '',
          classes: 'w-1/3',
        },
      ],
      sorting_by: '',
      vueDossierPromoteurs: [],
      current_page: 1,
      last_page: 1,
      total_page: 1,
      isFetching: false,
    }
  },
  methods: {
    getValue(object, key) {
      if (
        typeof object === 'object' &&
        object !== null &&
        object.hasOwnProperty(key)
      ) {
        return object[key]
      }

      return '--'
    },
    getVueDossierPromoteurs() {
      this.isFetching = true
      this.$http
        .get(
          `admin/dossier-promoteurs/${this.$route.params.id}/vues?page=${this.current_page}`,
        )
        .then((response) => {
          console.log('dfdf')
          this.vueDossierPromoteurs.push(...response.data.data)
          this.current_page = response.data.current_page
          this.last_page = response.data.last_page
          this.isFetching = false
        })
    },
    isVisible() {
      if (this.last_page === this.current_page) {
        return
      }
      this.current_page++
      this.getVueDossierPromoteurs()
    },
  },
}
</script>
<style lang="scss" scoped>
.btn-action {
  @apply flex items-center justify-center w-10 h-10 mr-2 text-white rounded-full cursor-pointer;
}
</style>
